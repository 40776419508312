import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  MenuItem,
  Box,
  FormControl,
  InputLabel,
  InputAdornment,
  Modal,
  Select,
  TableContainer,
  TableRow,
  TableBody,
  Table,
  TableCell,
  TableHead,
  Paper,
  FormHelperText,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DateTimePickerComponent from "../../common/DateTimePickerComponent";
import styles from "./GenerateQuotation.module.scss";
import AutoCompleteComponent from "../../common/AutoCompleteComponent";
import { serviceProvider } from "../../../provider/serviceProvider";
import { IAirport } from "../../../interface/IAirport";
import departureIcon from "../../../assets/icons/depature-icon.svg";
import destinationIcon from "../../../assets/icons/destination-icon.svg";
import seatIcon from "../../../assets/icons/seat.svg";
import ButtonComponent from "../../common/ButtonComponent";
import { toast } from "react-toastify";
import dayjs, { Dayjs } from "dayjs";
import { Mails } from "../../../pages/mails/mails";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import SendIcon from "@mui/icons-material/Send";
import SpinLoader from "../../../components/loader/SpinLoader";
import EditQuotationModal from "./EditQuotationModal";
import EditQuotation from "./EditQuotationModal";
import CommonModal from "../../modal/CommonModal";
import EditRouteQuotation from "./EditRouteQuotation";
import EditAircraftAndAmountCurrencyWithRoute from "./EditAircraft";

interface Route {
  from: string;
  fromAirportName: string;
  to: string;
  toAirportName: string;
  fhandler: string;
  thandler: string;
  pcount: string;
  date: string;
}

interface AircraftRoute {
  route: Route;
  flightDuration: string;
  hours?: number;
  minutes?: number;
}

interface Aircraft {
  aircraftName: string;
  aircraftId: string;
  assignedRoutes: AircraftRoute[];
  currency: string;
  amount: string;
}

interface QuotationData {
  aircraftAndCurrencyAndAmount: AircraftData[];
}

interface AircraftData {
  aircraftName: string;
  aircraftId: string;
  airportToFrom: AirportRoute[];
  currency: string; // Added currency
  amount: string; // Added amount
}

interface AirportRoute {
  fromAirportName: string;
  toAirportName: string;
  fromAirportId: string;
  toAirportId: string;
  passengerCount: string;
  startDt: string;
  fromHandler: string;
  toHandler: string;
  flightDuration: string;
}
interface EditableAirportRoute extends AirportRoute {
  routeIndex: number;
  groupIndex: number;
}

const QuotationForm = () => {
  const [globalRoutes, setGlobalRoutes] = useState<Route[]>([]); 
// console.log("globalRoutes"+JSON.stringify(globalRoutes));
  const [newGlobalRoute, setNewGlobalRoute] = useState<Route>({
    from: "",
    to: "",
    fromAirportName: "",
    toAirportName: "",
    fhandler: "",
    thandler: "",
    pcount: "",
    date: "",
  }); // Temporary route input
  const [aircrafts, setAircrafts] = useState<Aircraft[]>([]); 
  console.log("aircrafts Data:", JSON.stringify(aircrafts, null, 2));
  const [currentAircraftName, setCurrentAircraftName] = useState(""); 
  const [currentAircraftId, setCurrentAircraftId] = useState(""); 
  const [currentAssignedRoutes, setCurrentAssignedRoutes] = useState<
    AircraftRoute[]
  >([]); // Routes assigned to current aircraft
  const [currentCurrency, setCurrentCurrency] = useState(""); 
  const [currentAmount, setCurrentAmount] = useState(""); 
  const [validityNumber, setValidityNumber] = useState("2");
  const [validityType, setValidityType] = useState("DAYS");  
  const [quotationGroups, setQuotationGroups] = useState<QuotationData[]>([]); 
  const removeLastAircraft = () => {
    const updatedAircrafts = [...aircrafts];
    updatedAircrafts.pop(); 
    setAircrafts(updatedAircrafts);
  };
  const currency = [
    { currencyId: "currency1", name: "INR" },
    { currencyId: "currency2", name: "USD" },
  ];

  const ValidityType = [
    { ValidityId: "validity1", name: "HOURS" },
    { ValidityId: "validity2", name: "DAYS" },
  ];

  const [routeErrors, setRouteErrors] = useState({
    from: "",
    to: "",
    pcount: "",
    date: "",
  });
  const [routeRefreshKey, setRouteRefreshKey] = useState(0); 

  const addGlobalRoute = () => {
    // console.log("Add Route button clicked");
    // console.log("newGlobalRoute:", newGlobalRoute);

    const validationErrors = {
        from: "",
        to: "",
        pcount: "",
        date: "",
      };

    if (!newGlobalRoute.from.trim()) {
      validationErrors.from = "Missing Start Airport";
    }
    if (!newGlobalRoute.to.trim()) {
      validationErrors.to = "Missing Destination Airport";
    }
    if (!newGlobalRoute.pcount.trim()) {
      validationErrors.pcount = "Missing Passenger Count";
    }
    if (!newGlobalRoute.date.trim()) {
      validationErrors.date = "Missing Start Date";
    }

    if (
      validationErrors.from ||
      validationErrors.to ||
      validationErrors.pcount ||
      validationErrors.date
    ) {
      setRouteErrors(validationErrors);
      // console.log("Validation failed:", validationErrors);
    } else {
      setGlobalRoutes([...globalRoutes, newGlobalRoute]);
      // console.log("Updated globalRoutes:", [...globalRoutes, newGlobalRoute]);

      setNewGlobalRoute({
        from: "",
        to: "",
        fromAirportName: "",
        toAirportName: "",
        fhandler: "",
        thandler: "",
        pcount: "",
        date: "",
      });

      // Clear errors
      setRouteErrors({
        from: "",
        to: "",
        pcount: "",
        date: "",
      });
      setRouteRefreshKey((prevKey) => prevKey + 1);
    }
  };

  const assignAllRoutesToAircraft = (routes: Route[]) => {
    const newAssignedRoutes = routes.filter((route) =>
      !currentAssignedRoutes.find(
        (r) =>
          r.route.from === route.from &&
          r.route.to === route.to &&
          r.route.fhandler === route.fhandler &&
          r.route.thandler === route.thandler &&
          r.route.pcount === route.pcount &&
          r.route.date === route.date
      )
    );
  
    setCurrentAssignedRoutes([
      ...currentAssignedRoutes,
      ...newAssignedRoutes.map((route) => ({ route, flightDuration: "" })),
    ]);
  };
  
  const updateRouteDuration = (
    index: number,
    field: "hours" | "minutes",
    value: string
  ) => {
    const updatedAssignedRoutes = [...currentAssignedRoutes];
    const parsedValue = value === "" ? undefined : parseInt(value, 10);
    updatedAssignedRoutes[index][field] = parsedValue;
    const hours = updatedAssignedRoutes[index].hours || 0;
    const minutes = updatedAssignedRoutes[index].minutes || 0;
    const hoursText = hours > 0 ? `${hours} hours` : "";
    const minutesText = minutes > 0 ? `${minutes} minutes` : "";
    const flightDuration =
      `${hoursText}${hours && minutes ? " " : ""}${minutesText}` || "N/A";

    updatedAssignedRoutes[index].flightDuration = flightDuration;

    setCurrentAssignedRoutes(updatedAssignedRoutes);
  };
  const [timeError, setTimeError] = useState("");  

const saveAircraft = () => {
  let errorMessage = "";
  const hasEmptyDuration = currentAssignedRoutes.some(route => !route.hours && !route.minutes); // Check for empty rows

  if (hasEmptyDuration) {
    errorMessage = "Please provide either Hours or Minutes for each route.";
  }

  if (
    currentAircraftName.trim() &&
    currentAircraftId.trim() &&
    currentAssignedRoutes.length > 0 &&
    currentCurrency &&
    currentAmount
  ) {
    if (errorMessage) {
      setTimeError(errorMessage);
    } else {
      setAircrafts([
        ...aircrafts,
        {
          aircraftName: currentAircraftName,
          aircraftId: currentAircraftId,
          assignedRoutes: currentAssignedRoutes,
          currency: currentCurrency,
          amount: currentAmount,
        },
      ]);
      setCurrentAircraftName("");
      setCurrentAircraftId("");
      setCurrentAssignedRoutes([]);
      setCurrentCurrency("");
      setCurrentAmount("");
      setAircraftRefreshKey((prevKey) => prevKey + 1);
      setTimeError("");
    }
  } else {
    setTimeError("Please fill in all the required fields.");
  }
};

  

  const [pdfUrl, setPdfUrl] = useState("");
  const [showQuotationActions, setShowQuotationActions] = useState(false);
  const [loader, setLoader] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);  
  const [validityNumberError, setValidityNumberError] = useState<string | null>(null);

  const saveQuotation = () => {
    if (!validityNumber) {
        setValidityNumberError("Validity Number is required!");
        return;
      }
    setLoader(true);
    const data = {
      quotationsRequest: quotationGroups.map((group) => ({
        aircraftAndCurrencyAndAmount: group.aircraftAndCurrencyAndAmount.map(
          (aircraft) => ({
            aircraftName: aircraft.aircraftName,
            aircraftId: aircraft.aircraftId,
            airportToFrom: aircraft.airportToFrom.map((assignedRoute) => ({
              fromAirportId: assignedRoute.fromAirportId,
              toAirportId: assignedRoute.toAirportId,
              passengerCount: assignedRoute.passengerCount,
              startDt: assignedRoute.startDt,
              fromHandler: assignedRoute.fromHandler,
              toHandler: assignedRoute.toHandler,
              flightDuration: assignedRoute.flightDuration,
            })),
            currency: aircraft.currency, 
            amount: aircraft.amount,
          })
        ),
      })),
      validityInfo: {
        validityNumber,
        validityType,
      },
    };
    serviceProvider.InstantQuotationService.post(data)
      .then((res: any) => {
       
        if (res?.responseCode === 200) {
          toast.success(res.message);
          setPdfUrl(res.data.pdfS3Url);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setShowQuotationActions(true);
        setLoader(false);
        setRefreshKey((prevKey) => prevKey + 1);
        setQuotationGroups([]);
        setGlobalRoutes([]);
      });
    setValidityNumber("2");  
    setValidityType("DAYS"); 
    setValidityNumberError(null); 
  };
  useEffect(() => {
  }, [refreshKey]);
  
  const [aircraftRefreshKey, setAircraftRefreshKey] = useState(0);  

  const addQuotationGroup = () => {
    const newGroup: QuotationData = {
      aircraftAndCurrencyAndAmount: aircrafts.map((aircraft) => ({
        aircraftName: aircraft.aircraftName,
        aircraftId: aircraft.aircraftId,
        airportToFrom: aircraft.assignedRoutes.map((assignedRoute) => ({
          fromAirportId: assignedRoute.route.from,
          toAirportId: assignedRoute.route.to,
          fromAirportName: assignedRoute.route.fromAirportName,
          toAirportName: assignedRoute.route.toAirportName,
          passengerCount: assignedRoute.route.pcount,
          startDt: assignedRoute.route.date,
          fromHandler: assignedRoute.route.fhandler,
          toHandler: assignedRoute.route.thandler,
          flightDuration: assignedRoute.flightDuration,
        })),
        currency: aircraft.currency, 
        amount: aircraft.amount, 
      })),
    };

    setQuotationGroups([...quotationGroups, newGroup]);
    setAircrafts([]);
    setGlobalRoutes([]);
  };

  const removeLastGlobalRoute = () => {
    const updatedGlobalRoutes = [...globalRoutes];
    updatedGlobalRoutes.pop(); 
    setGlobalRoutes(updatedGlobalRoutes); 
  };
  const removeLastRoute = () => {
    const updatedAssignedRoutes = [...currentAssignedRoutes];
    updatedAssignedRoutes.pop(); 
    setCurrentAssignedRoutes(updatedAssignedRoutes);
  };

  const removeLastQuotationGroup = () => {
    const updatedQuotationGroups = [...quotationGroups];
    updatedQuotationGroups.pop(); 
    setQuotationGroups(updatedQuotationGroups);
  };

  const [airports, setAirports] = useState<IAirport[]>([]);
  const [fleetAircrafts, setFleetAircrafts] = useState<any[]>([]);
  const getAllFleetAircrafts = () => {
    const param = `aircrafts?aircraftId=NA&fleetId=NA&minNumberOfPassengers=0&customUrl=NA`;
    serviceProvider.aircraftService
      .get(param)
      .then((res: any) => {
        if (res.responseCode === 200) {
          setFleetAircrafts(res.data);
        }
      })
      .catch((err: any) => {});
  };
  const getAllAirportsList = () => {
    serviceProvider.airportService
      .getAllAirports()
      .then((res) => {
        if (res.responseCode === 200) {
          setAirports(res.data);
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getAllAirportsList();
    getAllFleetAircrafts();
  }, []);
  const handleDownloadClick = () => {
    if (pdfUrl) {
      window.open(pdfUrl, "_blank");
    } else {
      toast.error(
        "PDF not available. Please submit the form to generate a quotation."
      );
    }
  };

  const [open, setOpen] = useState(false);
  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
      const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true, 
    };
  
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  };
  const [editOpen, setEditOpen] = useState(false);
  const [editData, setEditData] = useState<EditableAirportRoute | null>(null);
  // Open modal and set current row data
  const handleEditClick = (route: AirportRoute, groupIndex: number, routeIndex: number) => {
    // Pass both groupIndex and routeIndex to editData
    setEditData({ ...route, groupIndex, routeIndex });
    setEditOpen(true);
  };
  
  

  // Handle modal close
  const handleClose = () => {
    setEditOpen(false);
    setEditData(null);
  };
  const handleDateChange = (value: Date | null) => {
    if (editData) {
      setEditData({
        ...editData,
        startDt: value ? value.toISOString() : "", // Convert Date object to ISO string
      });
    }
  };
  
  // Handle field changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (editData) {
      setEditData({
        ...editData,
        [e.target.name]: e.target.value,
      });
    }
  };
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);

  // Function to parse the flightDuration into hours and minutes
  const parseDuration = (duration: string) => {
    const hoursMatch = duration.match(/(\d+)\s*hours?/);
    const minutesMatch = duration.match(/(\d+)\s*minutes?/);

    return {
      hours: hoursMatch ? parseInt(hoursMatch[1]) : 0,
      minutes: minutesMatch ? parseInt(minutesMatch[1]) : 0,
    };
  };
  useEffect(() => {
    if (editData && editData.flightDuration) {
      const { hours, minutes } = parseDuration(editData.flightDuration);
      setHours(hours);
      setMinutes(minutes);
    }
  }, [editData]);
 // Function to handle the change for duration fields (hours or minutes)
 const handleDurationChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  type: 'hours' | 'minutes'
) => {
  let value = e.target.value ? parseInt(e.target.value) : 0;

  // Ensure 'minutes' does not exceed 59
  if (type === 'minutes' && value > 59) {
    value = 59;
  }

  if (type === 'hours') {
    setHours(value);
  } else if (type === 'minutes') {
    setMinutes(value);
  }
};


  // Save changes
  const handleSave = () => {
    if (!editData || editData.routeIndex === undefined || editData.groupIndex === undefined) return;
  
    const flightDuration = `${hours} hours ${minutes} minutes`;
  
    // Update the editData with the new flightDuration
    const updatedEditData = {
      ...editData,
      flightDuration, // Update the flightDuration field
    };
  
    // Update the quotationGroups data
    const updatedData = quotationGroups.map((group, groupIndex) => {
      // Check if the groupIndex matches
      if (groupIndex === editData.groupIndex) {
        return {
          ...group,
          aircraftAndCurrencyAndAmount: group.aircraftAndCurrencyAndAmount.map((aircraft, aircraftIndex) => {
            return {
              ...aircraft,
              airportToFrom: aircraft.airportToFrom.map((route, routeIndex) => {
                // Ensure that the routeIndex and groupIndex match to update the correct route
                if (routeIndex === editData.routeIndex && groupIndex === editData.groupIndex) {
                  return { ...route, ...updatedEditData }; // Update only the matched route
                }
                return route;
              }),
            };
          }),
        };
      }
      return group; // Return other groups unchanged
    });
  
    // Update the state with the new data
    setQuotationGroups(updatedData);
  
    // Log the updated data for debugging
    // console.log("Updated Data:", JSON.stringify(updatedData, null, 2));
  
    // Close the modal
    handleClose();
  };
  
  
  

const [error, setError] = useState<string | null>(null);
const [data, setData] = useState<any | null>({
  quotationsRequest: quotationGroups.map((group) => ({
    aircraftAndCurrencyAndAmount: group.aircraftAndCurrencyAndAmount.map(
      (aircraft) => ({
        aircraftName: aircraft.aircraftName,
        aircraftId: aircraft.aircraftId,
        airportToFrom: aircraft.airportToFrom.map((assignedRoute) => ({
          fromAirportId: assignedRoute.fromAirportId,
          toAirportId: assignedRoute.toAirportId,
          passengerCount: assignedRoute.passengerCount,
          startDt: assignedRoute.startDt,
          fromHandler: assignedRoute.fromHandler,
          toHandler: assignedRoute.toHandler,
          flightDuration: assignedRoute.flightDuration,
        })),
        currency: aircraft.currency,
        amount: aircraft.amount,
      })
    ),
  })),
  validityInfo: {
    validityNumber,
    validityType,
  },
}); // Initialize the prepared data
// console.log("data"+data)
const [showModal, setShowModal] = useState(false); // Control modal visibility
const [editedData, setEditedData] = useState(null); // Data being edited

const openEditModal = () => {
  if (data) {
    setEditedData(data); // Pass data to modal for editing
    setShowModal(true);
  } else {
    toast.error("No data available to edit!");
  }
};
// console.log("quotationGroups"+JSON.stringify(quotationGroups));
const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

  const handleUpdateQuotation = (updatedQuotationGroups: any[]) => {
    setQuotationGroups(updatedQuotationGroups); // Update the state with the modified quotation data
    setIsModalOpen(false); // Close the modal after update
  };

  const openModal = () => {
    setIsModalOpen(true); // Open the modal when you want to edit the quotation
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
  };
const [isRouteModalOpen, setIsRouteModalOpen] = useState(false); // State to control modal visibility

  const handleUpdateRouteQuotation = (updatedRouteQuotationGroups: any[]) => {
    setGlobalRoutes(updatedRouteQuotationGroups); // Update the state with the modified quotation data
    setIsRouteModalOpen(false); // Close the modal after update
  };

  const openRouteModal = () => {
    setIsRouteModalOpen(true); // Open the modal when you want to edit the quotation
  };

  const closeRouteModal = () => {
    setIsRouteModalOpen(false); // Close the modal
  };
const [isAircraftModalOpen, setIsAircraftModalOpen] = useState(false); // State to control modal visibility

  const handleUpdateAircraftQuotation = (updatedRouteQuotationGroups: any[]) => {
    setAircrafts(updatedRouteQuotationGroups); // Update the state with the modified quotation data
    setIsAircraftModalOpen(false); // Close the modal after update
  };

  const openAircraftModal = () => {
    setIsAircraftModalOpen(true); // Open the modal when you want to edit the quotation
  };

  const closeAircraftModal = () => {
    setIsAircraftModalOpen(false); // Close the modal
  };
  if (loader) {
    return <SpinLoader />;
  }
  return (
    <Box className={styles.container}>
            {/* <Button onClick={openEditModal}>Edit Quotation</Button> */}

            
      
      {/* Modal with EditQuotation Component inside */}
      <Modal 
      open={isModalOpen} 
      onClose={closeModal}
      className={styles.modalStyling}
      > 
      <Grid container className={styles.modalContainer}>
          <Grid item xs={12}>
            <Button className={styles.modalCloseBtn} onClick={closeModal}>
              <CloseIcon />
            </Button>
          </Grid>

          <Grid item xs={12} className="modal-body">
          <EditQuotation 
          quotationGroups={quotationGroups} 
          onUpdateQuotation={handleUpdateQuotation} 
          airports={airports}
          fleetAircrafts={fleetAircrafts}
          currency={currency}
          onClose={closeModal}
        />
          </Grid>
        </Grid>
         
       </Modal>
      <Modal 
      open={isRouteModalOpen} 
      onClose={closeRouteModal}
      className={styles.modalStyling}
      > 
      <Grid container className={styles.modalContainer}>
          <Grid item xs={12}>
            <Button className={styles.modalCloseBtn} onClick={closeRouteModal}>
              <CloseIcon />
            </Button>
          </Grid>

          <Grid item xs={12} className="modal-body">
          <EditRouteQuotation 
          globalRoutes={globalRoutes} 
          onUpdateQuotation={handleUpdateRouteQuotation} 
          airports={airports}
          onClose={closeRouteModal}
        />
          </Grid>
        </Grid>
         
       </Modal>
      <Modal 
      open={isAircraftModalOpen} 
      onClose={closeAircraftModal}
      className={styles.modalStyling}
      > 
      <Grid container className={styles.modalContainer}>
          <Grid item xs={12}>
            <Button className={styles.modalCloseBtn} onClick={closeAircraftModal}>
              <CloseIcon />
            </Button>
          </Grid>

          <Grid item xs={12} className="modal-body">
          <EditAircraftAndAmountCurrencyWithRoute 
          aircrafts={aircrafts} 
          fleetAircrafts={fleetAircrafts} 
          onUpdateQuotation={handleUpdateAircraftQuotation} 
          airports={airports}
          onClose={closeAircraftModal}
        />
          </Grid>
        </Grid>
         
       </Modal>
      {/* </CommonModal> */}
      <Grid container >
        <Grid container spacing={2} mb={4} className={styles.ItenaryConatiner} key={routeRefreshKey}>
        <Grid item xs={12} md={3}>
  <Grid container className={styles.depatureArrivalAirportBox}>
    <Grid item xs={12} className={styles.border}>
      <AutoCompleteComponent
        options={airports} // Pass the list of airport options
        getOptionLabel={(option: IAirport) =>
          `${option.name} (${option.icaoCode}) - ${option.city}`
        }
        renderOption={(props: any, option: IAirport) => (
          <Box
            sx={{
              width: "100%",
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            }}
            {...props}
          >
            <Typography textAlign="left">
              {option.name} ({option.icaoCode}) - {option.city}
            </Typography>
          </Box>
        )}
        placeholder="From"
        value={newGlobalRoute.from} // Map `from` to the correct option
        onChange={(event, selectedOption: IAirport | null) => {
          // console.log("Selected Option:", selectedOption); // Log the selected option

          const updatedNewGlobalRoute = {
            ...newGlobalRoute,
            from: selectedOption ? selectedOption.airportId : "",
            fromAirportName: selectedOption ? selectedOption.name : "",
          };
          setNewGlobalRoute(updatedNewGlobalRoute);

          if (!updatedNewGlobalRoute.from) {
            setRouteErrors((prevErrors) => ({
              ...prevErrors,
              from: "The 'From' field is required.",
            }));
          } else if (updatedNewGlobalRoute.from === updatedNewGlobalRoute.to) {
            setRouteErrors((prevErrors) => ({
              ...prevErrors,
              from: "Start and destination airports cannot be the same",
              
            }));
          } else {
            setRouteErrors((prevErrors) => ({
              ...prevErrors,
              from: "",
            }));
          }
        }}
        isOptionEqualToValue={(option, value) =>
          option.airportId === value.airportId
        }
      >
        <img
          src={departureIcon}
          alt="departure-icon"
          height="25px"
          width="25px"
        />
      </AutoCompleteComponent>
    </Grid>
  </Grid>
  {routeErrors.from && <span className="modalError">{routeErrors.from}</span>}
</Grid>


          <Grid item xs={12} sm={6} md={3}>
            <Grid container className={styles.quotationInput}>
              <TextField
                fullWidth
                size="small"
                type="text"
                value={newGlobalRoute.fhandler}
                onChange={(e) =>
                  setNewGlobalRoute({
                    ...newGlobalRoute,
                    fhandler: e.target.value,
                  })
                }
                variant="standard"
                placeholder="Enter from handler"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={3}>
  <Grid container className={styles.depatureArrivalAirportBox}>
    <Grid item xs={12} className={styles.border}>
      <AutoCompleteComponent
        options={airports} // Pass the list of airport options
        getOptionLabel={(option: IAirport) =>
          `${option.name} (${option.icaoCode}) - ${option.city}`
        }
        renderOption={(props: any, option: IAirport) => (
          <Box
            sx={{
              width: "100%",
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            }}
            {...props}
          >
            <Typography textAlign="left">
              {option.name} ({option.icaoCode}) - {option.city}
            </Typography>
          </Box>
        )}
        placeholder="To"
        value={newGlobalRoute.to} // Map `to` to the correct option
        onChange={(event, selectedOption: IAirport | null) => {
          // console.log("Selected Option:", selectedOption); // Log the selected option

          const updatedNewGlobalRoute = {
            ...newGlobalRoute,
            to: selectedOption ? selectedOption.airportId : "",
            toAirportName: selectedOption ? selectedOption.name : "",
          };

          // Update the route and errors
          setNewGlobalRoute(updatedNewGlobalRoute);

          if (!updatedNewGlobalRoute.to) {
            setRouteErrors((prevErrors) => ({
              ...prevErrors,
              to: "The 'To' field is required.",
            }));
          } else if (updatedNewGlobalRoute.from === updatedNewGlobalRoute.to) {
            setRouteErrors((prevErrors) => ({
              ...prevErrors,
              
              to: "Start and destination airports cannot be the same",
            }));
          } else {
            setRouteErrors((prevErrors) => ({
              ...prevErrors,
              to: "",
            }));
          }
        }}
        isOptionEqualToValue={(option, value) =>
          option.airportId === value.airportId
        }
      >
        <img
          src={destinationIcon}
          alt="departure-icon"
          height="25px"
          width="25px"
        />
      </AutoCompleteComponent>
    </Grid>
  </Grid>
  {routeErrors.to && <span className="modalError">{routeErrors.to}</span>}
</Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Grid container className={styles.quotationInput}>
              <TextField
                fullWidth
                size="small"
                type="text"
                value={newGlobalRoute.thandler}
                onChange={(e) =>
                  setNewGlobalRoute({
                    ...newGlobalRoute,
                    thandler: e.target.value,
                  })
                }
                variant="standard"
                placeholder="Enter to handler"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} sm={6}>
            <Grid container className={styles.searchInput}>
              <TextField
                className={styles.customInput}
                placeholder="Number of Passengers"
                value={newGlobalRoute.pcount}
                onChange={(e) =>
                  setNewGlobalRoute({
                    ...newGlobalRoute,
                    pcount: e.target.value,
                  })
                }
                size="small"
                type="number"
                fullWidth
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={seatIcon}
                        alt="seat icon"
                        height="25px"
                        width="25px"
                      />
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
              />
            </Grid>
            {routeErrors.pcount && (
          <span className="modalError">{routeErrors.pcount}</span>
        )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid container className={styles.depatureArrivalAirportBox}>
              <DateTimePickerComponent
                placeholder="Start Date and Time"
                disablePast={true}
                value={newGlobalRoute.date ? dayjs(newGlobalRoute.date) : null}
                onChange={(value) =>
                  setNewGlobalRoute({
                    ...newGlobalRoute,
                    date: value ? value.toISOString() : "", // Ensure the date is stored as a string
                  })
                }
              />
            </Grid>
            {routeErrors.date && <span className="modalError">{routeErrors.date}</span>}

          </Grid>

          <Grid item xs={12} textAlign='end'>
            <ButtonComponent fullWidth={false} label='Add to route list' onClick={addGlobalRoute} />
          </Grid>
        </Grid>
        <Grid container mb={1}>
        <Grid item xs={12} sm={6} >
          <Typography variant="h6">Step 1: Route List</Typography>
        </Grid>
        <Grid item xs={12} sm={6} className={styles.addItenaryBtn}>
{ aircrafts.length === 0 && globalRoutes.length > 0 && (
          <ButtonComponent
            label="Edit Route"
            fullWidth={false}
            onClick={openRouteModal}
          />
        )} 
        </Grid>
        </Grid>
        <Grid xs={12} mb={2}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="small-text">From Airport</TableCell>
                  <TableCell className="small-text">From Handler</TableCell>
                  <TableCell className="small-text">To Airport</TableCell>
                  <TableCell className="small-text">To Handler</TableCell>
                  <TableCell className="small-text">Passenger Count</TableCell>
                  <TableCell className="small-text">Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {globalRoutes.map((route, index) => (
                  <TableRow key={index}>
                    <TableCell className="small-text">
                      {route.fromAirportName}
                    </TableCell>
                    <TableCell className="small-text">
                      {route.fhandler}
                    </TableCell>
                    <TableCell className="small-text">
                      {route.toAirportName}
                    </TableCell>
                    <TableCell className="small-text">
                      {route.thandler}
                    </TableCell>
                    <TableCell className="small-text">{route.pcount}</TableCell>
                    <TableCell className="small-text">{formatDate(route.date)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {globalRoutes.length > 0 && (
          <Grid item xs={12} className={styles.centerItem}>
            <Button
              onClick={removeLastGlobalRoute}
              startIcon={<RemoveIcon />}
              disabled={globalRoutes.length === 0}
            >
              Remove last row
            </Button>
          </Grid>
        )}

{globalRoutes.length > 0 && (
        <Grid item xs={12} mb={1}>
          <Typography variant="h6">Add Aircraft</Typography>
        </Grid>
)}

{globalRoutes.length > 0 && (

        <Grid container spacing={2}  >
          <Grid item xs={12} sm={6} >
            <Grid container className={styles.depatureArrivalAirportBox}>
              <Grid item xs={12} className={styles.border} key={aircraftRefreshKey} >
                <AutoCompleteComponent
                  options={fleetAircrafts}
                  value={currentAircraftName}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.name}
                    </Box>
                  )}
                  onChange={(e, selectedOption) => {
                    setCurrentAircraftName(selectedOption.name);
                    setCurrentAircraftId(selectedOption.aircraftId); // Assuming you have a setAircraftId function
                  }}
                  placeholder="Choose an aircraft"
                ></AutoCompleteComponent>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid container className={styles.quotationInput}>
              <TextField
                fullWidth
                size="small"
                type="number"
                value={currentAmount}
                onChange={(e) => setCurrentAmount(e.target.value)}
                variant="standard"
                placeholder="Enter amount"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3} key={aircraftRefreshKey}>
            <Grid container className={styles.quotationCurrency} >
              <AutoCompleteComponent
                options={currency}
                value={currentCurrency}
                getOptionLabel={(option) => option.name}
                onChange={(e, selectedUser) => {
                  setCurrentCurrency(selectedUser ? selectedUser.name : ""); // Handle case if no user is selected
                }}
                placeholder="Currency"
              />
            </Grid>
          </Grid>
          { currentAircraftName && currentCurrency && currentAmount && (
          <Grid item xs={12} sx={{display:'flex',justifyContent:'center'}} mt={2}>
          <Button
          className="common-button-filled"
          startIcon={<AddIcon/>}
    onClick={() => assignAllRoutesToAircraft(globalRoutes)}
  >
    Add Aircraft
  </Button>
  </Grid>
          )}
        </Grid>
        
)}
    { currentAircraftName && currentCurrency && currentAmount && (

     <Grid container spacing={3}>
        <Grid item xs={12} mt={2} mb={2}>
        <TableContainer component={Paper} style={{ margin: "10px 0" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="small-text">Flight Duration</TableCell>
                <TableCell className="small-text">From Airport</TableCell>
                <TableCell className="small-text">From Handler</TableCell>
                <TableCell className="small-text">To Airport</TableCell>
                <TableCell className="small-text">To Handler</TableCell>
                <TableCell className="small-text">Passenger Count</TableCell>
                <TableCell className="small-text">Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentAssignedRoutes.map((assignedRoute, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          size="small"
                          placeholder="Hours"
                          value={currentAssignedRoutes[index]?.hours || ""}
                          onChange={(e) =>
                            updateRouteDuration(index, "hours", e.target.value)
                          }
                          fullWidth
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          size="small"
                          placeholder="Minutes"
                          value={currentAssignedRoutes[index]?.minutes || ""}
                          onChange={(e) => {
                            const inputValue = e.target.value;               
                            if (inputValue && (parseInt(inputValue, 10) > 59 || parseInt(inputValue, 10) < 0)) {
                              return; // Do not update if the value is greater than 59 or less than 0
                            }
                    
                            updateRouteDuration(index, "minutes", inputValue);
                          }}
                          fullWidth
                          type="number"
                          InputProps={{ inputProps: { min: 0, max: 59 } }}
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell className="small-text">
                    {assignedRoute.route.fromAirportName}
                  </TableCell>
                  <TableCell className="small-text">
                    {assignedRoute.route.fhandler}
                  </TableCell>
                  <TableCell className="small-text">
                    {assignedRoute.route.toAirportName}
                  </TableCell>
                  <TableCell className="small-text">
                    {assignedRoute.route.thandler}
                  </TableCell>
                  <TableCell className="small-text">
                    {assignedRoute.route.pcount}
                  </TableCell>
                  <TableCell className="small-text">
                    {formatDate(assignedRoute.route.date)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Grid>
        </Grid>
)}
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        {timeError && <span className="modalError">{timeError}</span>}
        </Grid>
        <Grid item xs={12} sm={6}>
        {currentAssignedRoutes.length > 0 && (
          <Button
          startIcon={<RemoveIcon />}
            onClick={removeLastRoute}
          >
            Remove Last Route
          </Button>
          )}
        </Grid>
        
        {currentAircraftName && currentAircraftId && currentAssignedRoutes.length > 0 && currentCurrency && currentAmount && (
          <Grid container> 
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Typography variant="body2">*Click on Add aircraft list when all the aircrafts details are added for this route</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-end" }}
          mb={3}
        >
          <ButtonComponent label="Add to Aircraft List" fullWidth={false} onClick={saveAircraft} />
        </Grid>
        </Grid>
        )} 
        <Grid xs={12} mt={2} mb={2}>
        <Grid item xs={12}  className={styles.addItenaryBtn} textAlign='end' >
{aircrafts.length > 0 && (
          <ButtonComponent
            label="Edit AircraftList"
            fullWidth={false}
            onClick={openAircraftModal}
          />
        )} 
        </Grid>
        <Grid container mb={1}>
        <Grid item xs={12} >
          <Typography variant="h6">Step 2: Aircraft List</Typography>
        </Grid>
        
        </Grid>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="small-text">Aircraft Name</TableCell>
                <TableCell className="small-text">Route From Airport</TableCell>
                <TableCell className="small-text">From Handler</TableCell>
                <TableCell className="small-text">Route To Airport</TableCell>
                <TableCell className="small-text">To Handler</TableCell>
                <TableCell className="small-text">Passenger Count</TableCell>
                <TableCell className="small-text">Date</TableCell>
                <TableCell className="small-text">Flight Duration</TableCell>
                <TableCell className="small-text">Currency</TableCell>
                <TableCell className="small-text">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {aircrafts.map((aircraft, index) => (
                <React.Fragment key={index}>
                  {aircraft.assignedRoutes.map((route, routeIndex) => (
                    <TableRow key={routeIndex}>
                      {routeIndex === 0 && (
                        <TableCell rowSpan={aircraft.assignedRoutes.length}>
                          <strong>{aircraft.aircraftName}</strong>
                        </TableCell>
                      )}
                      <TableCell className="small-text">
                        {route.route.fromAirportName}
                      </TableCell>
                      <TableCell className="small-text">
                        {route.route.fhandler}
                      </TableCell>
                      <TableCell className="small-text">
                        {route.route.toAirportName}
                      </TableCell>
                      <TableCell className="small-text">
                        {route.route.thandler}
                      </TableCell>
                      <TableCell className="small-text">
                        {route.route.pcount}
                      </TableCell>
                      <TableCell className="small-text">
                        {formatDate(route.route.date)}
                      </TableCell>
                      <TableCell className="small-text">
                        {route.flightDuration || "No Duration"}
                      </TableCell>
                      {routeIndex === 0 && (
                        <>
                          <TableCell
                            className="small-text"
                            rowSpan={aircraft.assignedRoutes.length}
                          >
                            {aircraft.currency}
                          </TableCell>
                          <TableCell
                            className="small-text"
                            rowSpan={aircraft.assignedRoutes.length}
                          >
                            {aircraft.amount}
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Grid>
        <Grid xs={12} mt={1} mb={1}>
        {aircrafts.length > 0 && (
        <Button
        startIcon={<RemoveIcon/>}
          onClick={removeLastAircraft}
        >
          Remove Last Aircraft
        </Button>
        )}
        </Grid>
        {aircrafts.length > 0 && ( 
          <Grid container> 
          <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Typography variant="body2">*Click on Add to Itinerary list when the route and aircraft details are added
          </Typography>
        </Grid>
<Grid item xs={12} mt={2} mb={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <ButtonComponent label="Add to Itinerary List" fullWidth={false} onClick={addQuotationGroup}/>
        </Grid>
        </Grid>
        )}
        <Grid container >
        <Grid item xs={12}  key={refreshKey}>
        <Grid item xs={12} mb={1}>
          <Typography variant="h6">Final Step: Itinerary List</Typography>
        </Grid>
        <Box sx={{ overflowX: "auto" }}>
          <TableContainer component={Paper} sx={{width:'100%'}}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="small-text">Itinerary</TableCell>
                  <TableCell className="small-text">Aircraft Name</TableCell>
                  <TableCell className="small-text">Aircraft Amount</TableCell>
                  <TableCell className="small-text">Aircraft Currency</TableCell>
                  <TableCell className="small-text">
                    Route From Airport
                  </TableCell>
                  <TableCell className="small-text">From Handler</TableCell>
                  <TableCell className="small-text">Route To Airport</TableCell>
                  <TableCell className="small-text">To Handler</TableCell>
                  <TableCell className="small-text">Passenger Count</TableCell>
                  <TableCell className="small-text">Start Date</TableCell>
                  <TableCell className="small-text">Flight Duration</TableCell>
                  {/* <TableCell className="small-text">Actions</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {quotationGroups.map((group, groupIndex) => (
                  <React.Fragment key={groupIndex}>
                    {group.aircraftAndCurrencyAndAmount.map(
                      (aircraft, aircraftIndex) => (
                        <React.Fragment key={aircraftIndex}>
                          {aircraft.airportToFrom.map((route, routeIndex) => (
                            <TableRow key={routeIndex}>
                              {routeIndex === 0 && (
                                <TableCell
                                  rowSpan={aircraft.airportToFrom.length}
                                >
                                  Itinerary {groupIndex + 1}
                                </TableCell>
                              )}
                              {routeIndex === 0 && (
                                <TableCell
                                  rowSpan={aircraft.airportToFrom.length}
                                >
                                  <strong>{aircraft.aircraftName}</strong>
                                </TableCell>
                              )}
                              {routeIndex === 0 && (
                                <TableCell
                                  rowSpan={aircraft.airportToFrom.length}
                                >
                                  <strong>{aircraft.amount}</strong>
                                </TableCell>
                              )}
                              {routeIndex === 0 && (
                                <TableCell
                                  rowSpan={aircraft.airportToFrom.length}
                                >
                                  <strong>{aircraft.currency}</strong>
                                </TableCell>
                              )}
                              <TableCell className="small-text">
                                {route.fromAirportName}
                              </TableCell>
                              <TableCell className="small-text">
                                {route.fromHandler}
                              </TableCell>
                              <TableCell className="small-text">
                                {route.toAirportName}
                              </TableCell>
                              <TableCell className="small-text">
                                {route.toHandler}
                              </TableCell>
                              <TableCell className="small-text">
                                {route.passengerCount}
                              </TableCell>
                              <TableCell className="small-text">
                                {formatDate(route.startDt)}
                              </TableCell>
                              <TableCell className="small-text">
                                {route.flightDuration}
                              </TableCell>
                              {/* <TableCell>
                     <TableCell>
  <Button
    variant="outlined"
    onClick={() => handleEditClick(route, routeIndex,groupIndex)} // Pass routeIndex here
  >
    Edit
  </Button>
</TableCell>
                    </TableCell> */}
                            </TableRow>
                          ))}
                        </React.Fragment>
                      )
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          </Box>
        </Grid>
        </Grid>
 <div>
   
      <Modal open={editOpen} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          {editData && (
            <>
             <AutoCompleteComponent
  options={airports as IAirport[]}
  getOptionLabel={(option: IAirport) =>
    `${option.name} (${option.icaoCode}) - ${option.city}`
  }
  renderOption={(props: any, option: IAirport) => (
    <Box
      sx={{
        width: "100%",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      }}
      {...props}
    >
      <Typography textAlign="left">
        {option.name} ({option.icaoCode}) - {option.city}
      </Typography>
    </Box>
  )}
  placeholder="Select From Airport"
  value={editData.fromAirportName}
  onChange={(event, selectedOption: IAirport | null) => {
    if (selectedOption) {
      if (selectedOption.airportId === editData.toAirportId) {
        setError("From and Destination Airports cannot be the same.");
      } else {
        setError(null);
      }
      setEditData({
        ...editData,
        fromAirportId: selectedOption.airportId,
        fromAirportName: selectedOption.name,
      });
    }
  }}
/>
{error && (
  <Typography color="error" variant="body2" sx={{ mt: 1 }}>
    {error}
  </Typography>
)}


              <TextField
                label="From Handler"
                name="fromHandler"
                value={editData.fromHandler}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <AutoCompleteComponent
  options={airports as IAirport[]}
  getOptionLabel={(option: IAirport) =>
    `${option.name} (${option.icaoCode}) - ${option.city}`
  }
  renderOption={(props: any, option: IAirport) => (
    <Box
      sx={{
        width: "100%",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      }}
      {...props}
    >
      <Typography textAlign="left">
        {option.name} ({option.icaoCode}) - {option.city}
      </Typography>
    </Box>
  )}
  placeholder="Select Destination Airport"
  value={editData.toAirportName}
  onChange={(event, selectedOption: IAirport | null) => {
    if (selectedOption) {
      if (selectedOption.airportId === editData.fromAirportId) {
        setError("From and Destination Airports cannot be the same.");
      } else {
        setError(null);
      }
      setEditData({
        ...editData,
        toAirportId: selectedOption.airportId,
        toAirportName: selectedOption.name,
      });
    }
  }}
/>
{error && (
  <Typography color="error" variant="body2" sx={{ mt: 1 }}>
    {error}
  </Typography>
)}


              <TextField
                label="To Handler"
                name="toHandler"
                value={editData.toHandler}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Passenger Count"
                name="passengerCount"
                value={editData.passengerCount}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
             <DateTimePickerComponent
  placeholder="Start Date and Time"
  disablePast={true}
  value={editData?.startDt ? dayjs(editData.startDt) : null} // Convert ISO string to Date object
  onChange={handleDateChange} // Call the updated handler
/>

              {/* <TextField
                label="Start Date"
                name="startDt"
                value={editData.startDt}
                onChange={handleChange}
                fullWidth
                margin="normal"
              /> */}
              <Grid item xs={6}>
        <TextField
          label="Hours"
          name="hours"
          type="number"
          value={hours}
          onChange={(e) => handleDurationChange(e as React.ChangeEvent<HTMLInputElement>, 'hours')}
          fullWidth
          margin="normal"
        />
      </Grid>

      {/* Minutes Field */}
      <Grid item xs={6}>
        <TextField
          label="Minutes"
          name="minutes"
          type="number"
          value={minutes}
          onChange={(e) => handleDurationChange(e as React.ChangeEvent<HTMLInputElement>, 'minutes')}
          fullWidth
          margin="normal"
          inputProps={{ max: 59 }}
        />
      </Grid>
              <Box mt={2} display="flex" justifyContent="flex-end">
                <Button onClick={handleClose} sx={{ mr: 2 }}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={handleSave}>
                  Save
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </div>
        <Grid xs={12} mt={1} mb={1}>
        {quotationGroups.length > 0 && (
        <Button
        startIcon={<RemoveIcon/>}
          onClick={removeLastQuotationGroup}
        >
          Remove Last Itinerary
        </Button>
        )}
        </Grid>
        <Grid container spacing={2} className={styles.validity}>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="subtitle1" gutterBottom>
              Add validity
            </Typography>
            <Grid container className={styles.quotationInput}>
              <TextField
                id="validityNumber"
                fullWidth
                size="small"
                type="number"
                value={validityNumber}
                onChange={(e) => {
                    setValidityNumber(e.target.value);
                    setValidityNumberError(null); // Clear error when user starts typing
                  }}
                variant="standard"
                placeholder="Enter Number of Hours/days"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Grid>
            {validityNumberError && (
  <FormHelperText error>{validityNumberError}</FormHelperText>  // This will display the error message
)}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="subtitle1" gutterBottom>
              Add validity type
            </Typography>
            <Grid container className={styles.quotationCurrency}>
              <AutoCompleteComponent
                id="validityType"
                options={ValidityType}
                defaultValue={ValidityType[1]}
                value={validityType}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.name}
                  </Box>
                )}
                onChange={(event, selectedOption) => setValidityType(selectedOption?.name || "")}  // Update state with the selected value

                placeholder="Select Hours/Days"
              ></AutoCompleteComponent>
            </Grid>
          </Grid>
        </Grid>
        {/* <Button onClick={openModal}>Edit Quotation</Button>  */}
        <Grid item xs={12} className={styles.addItenaryBtn}>
        {quotationGroups.length > 0 && (
          <ButtonComponent
            label="Edit Quotation"
            fullWidth={false}
            onClick={openModal}
          />
        )} 
        </Grid>
        <Grid item xs={12} className={styles.addItenaryBtn}>
        {quotationGroups.length > 0 && (
          <ButtonComponent
            label="Generate Quotation"
            fullWidth={false}
            onClick={saveQuotation}
          />
        )} 
        </Grid>
       
      </Grid>
      {showQuotationActions && (
        <Grid container xs={12} className={styles.dlSndBtn} spacing={2}>
          <Grid item xs={12} sm={6} className={styles.downloadBtn}>
            <Button onClick={handleDownloadClick} startIcon={<DownloadIcon />}>
              Download Quotation
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} className={styles.sendBtn}>
            <Button onClick={handleOpenModal} startIcon={<SendIcon />}>
              Send Quotation in email
            </Button>
          </Grid>
        </Grid>
      )}
      <Modal
        open={open}
        onClose={handleCloseModal}
        className={styles.modalStyling}
      >
        <Grid container className={styles.modalContainer}>
          <Grid item xs={12}>
            <Button className={styles.modalCloseBtn} onClick={handleCloseModal}>
              <CloseIcon />
            </Button>
          </Grid>

          <Grid item xs={12} className="modal-body">
            <Box>
              <Mails pdfUrl={pdfUrl} />
            </Box>
          </Grid>
        </Grid>
      </Modal>
      
    </Box>
  );
};

export default QuotationForm;
