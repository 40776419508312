import React, { useState, useEffect } from "react";
import AutoCompleteComponent from "../../common/AutoCompleteComponent";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { IAirport } from "../../../interface/IAirport";
import styles from "./GenerateQuotation.module.scss";
import seatIcon from "../../../assets/icons/seat.svg";
import departureIcon from "../../../assets/icons/depature-icon.svg";
import destinationIcon from "../../../assets/icons/destination-icon.svg";
import DateTimePickerComponent from "../../common/DateTimePickerComponent";
import dayjs from "dayjs";
import ButtonComponent from "../../common/ButtonComponent";

const EditQuotation = ({
  quotationGroups,
  onUpdateQuotation,
  airports,
  fleetAircrafts,
  currency,
  onClose,
}: any) => {
  const [editedQuotationGroups, setEditedQuotationGroups] =
    useState<any[]>(quotationGroups);
    const [errorMessages, setErrorMessages] = useState<Record<
    string,
    string
  >>({});

  // Update local state when prop `quotationGroups` changes (e.g., if parent component changes)
  useEffect(() => {
    setEditedQuotationGroups(quotationGroups);
  }, [quotationGroups]);

  const handleInputChange = (
    groupIndex: number,
    aircraftIndex: number,
    field: string,
    value: any
  ) => {
    const updatedGroups = [...editedQuotationGroups];
    updatedGroups[groupIndex].aircraftAndCurrencyAndAmount[aircraftIndex][
      field
    ] = value;
    setEditedQuotationGroups(updatedGroups);
  };

  const updateRouteField = (
    groupIndex: number,
    aircraftIndex: number,
    routeIndex: number,
    field: string,
    value: any
  ) => {
    const updatedGroups = [...editedQuotationGroups];
    const updatedRoutes = [...updatedGroups[groupIndex].aircraftAndCurrencyAndAmount[aircraftIndex].airportToFrom];
    updatedRoutes[routeIndex] = {
      ...updatedRoutes[routeIndex],
      [field]: value,
    };

    updatedGroups[groupIndex].aircraftAndCurrencyAndAmount[aircraftIndex].airportToFrom = updatedRoutes;
    setEditedQuotationGroups(updatedGroups);
  };
  const handleAirportChange = (
    groupIndex: number,
    aircraftIndex: number,
    routeIndex: number,
    field: string,
    selectedOption: any
  ) => {
    const currentRoute =
      editedQuotationGroups[groupIndex].aircraftAndCurrencyAndAmount[aircraftIndex].airportToFrom[routeIndex];
    const updatedAirport = {
      [field]: selectedOption ? selectedOption.name : "",
      [`${field}Id`]: selectedOption ? selectedOption.airportId : "",
    };

    // Check for error: if the selected airport matches the other field (e.g., "from" and "to")
    if (
      (field === "fromAirportName" && selectedOption?.name === currentRoute.toAirportName) ||
      (field === "toAirportName" && selectedOption?.name === currentRoute.fromAirportName)
    ) {
      setErrorMessages((prev) => ({
        ...prev,
        [`${groupIndex}-${aircraftIndex}-${routeIndex}`]: "From and To Airport cannot be the same.",
      }));
    } else {
      // Clear error message for this specific route
      setErrorMessages((prev) => {
        const newErrors = { ...prev };
        delete newErrors[`${groupIndex}-${aircraftIndex}-${routeIndex}`];
        return newErrors;
      });
    }

    updateRouteField(groupIndex, aircraftIndex, routeIndex, field, updatedAirport[field]);
  };

  const getErrorMessage = (
    groupIndex: number,
    aircraftIndex: number,
    routeIndex: number
  ) => errorMessages[`${groupIndex}-${aircraftIndex}-${routeIndex}`];

  const handleSave = () => {
    // After editing, pass the updated data back to the parent (PostQuotation) component
    if(Object.keys(errorMessages).length === 0){
        onUpdateQuotation(editedQuotationGroups);

    }
  };
  
  return (
    <Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="h5">Edit Quotation</Typography>
      </Grid>
      {editedQuotationGroups.map((group, groupIndex) => (
        <Grid key={groupIndex}>
            <Grid item xs={12} mb={1}>
                <Typography variant="h6">{"Itenary " + (groupIndex+1)}</Typography>
              </Grid>
          {group.aircraftAndCurrencyAndAmount.map((aircraft, aircraftIndex) => (
            <Grid key={aircraftIndex}>
              <Grid item xs={12} mb={1}>
                <Typography variant="subtitle1">Aircraft Details</Typography>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    Aircraft Name:<b>{aircraft.aircraftName}</b>
                  </Typography>
                  <Grid container className={styles.depatureArrivalAirportBox}>
                    <Grid item xs={12} className={styles.border}>
                      <AutoCompleteComponent
                        options={fleetAircrafts} // Provide your list of aircraft names here
                        getOptionLabel={(option) => option.name} // How to display the option in the dropdown
                        value={aircraft.aircraftName}
                        renderOption={(props, option) => (
                          <Box component="li" {...props}>
                            {option.name}
                          </Box>
                        )}
                        onChange={(event, selectedOption: any) => {
                          // Update both aircraftName and aircraftId in the state
                          handleInputChange(
                            groupIndex,
                            aircraftIndex,
                            "aircraftName",
                            selectedOption ? selectedOption.name : ""
                          );
                          handleInputChange(
                            groupIndex,
                            aircraftIndex,
                            "aircraftId",
                            selectedOption ? selectedOption.aircraftId : ""
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="aircraftName"
                            placeholder="Enter aircraft name"
                            title="Enter the name of the aircraft"
                            variant="standard"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography>Amount:</Typography>
                  <Grid container className={styles.quotationInput}>
                    <TextField
                      fullWidth
                      size="small"
                      type="number"
                      variant="standard"
                      placeholder="Enter amount"
                      value={aircraft.amount}
                      onChange={(e) =>
                        handleInputChange(
                          groupIndex,
                          aircraftIndex,
                          "amount",
                          e.target.value
                        )
                      }
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography>Currency:</Typography>
                  <Grid container className={styles.quotationEditCurrency}>
                    <Select
                      value={aircraft.currency || ""} // Ensure the value is a string
                      onChange={(e) =>
                        handleInputChange(
                          groupIndex,
                          aircraftIndex,
                          "currency",
                          e.target.value
                        )
                      }
                      displayEmpty
                      fullWidth
                      sx={{
                        border: "none", // Removes the border
                        boxShadow: "none", // Removes any shadow
                        padding: " 0", // Adjust padding if needed
                        ".MuiOutlinedInput-notchedOutline": {
                          border: "none", // Ensures no outline in certain variants
                        },
                      }}
                    >
                      <MenuItem value="INR">INR</MenuItem>
                      <MenuItem value="USD">USD</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Grid>

              {/* <h4>.</h4> */}
              {aircraft.airportToFrom.map((route, routeIndex) => (
                // <div key={routeIndex}>

                <Grid
                  container
                  spacing={2}
                  mb={4}
                  className={styles.ItenaryConatiner}
                  key={routeIndex}
                >
                  <Grid item xs={12} md={0}>
                    <Typography variant="h6">Airport Routes</Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography>
                      From Airport:<b>{route.fromAirportName || airports.find((airport) => airport.airportId === route.fromAirportId)?.name}</b>
                    </Typography>
                    <Grid
                      container
                      className={styles.depatureArrivalAirportBox}
                    >
                      <Grid item xs={12} className={styles.border}>
                        <AutoCompleteComponent
                          options={airports} // List of airport options
                          getOptionLabel={(option: IAirport) =>
                            `${option.name} (${option.icaoCode}) - ${option.city}`
                          } // Custom label for each option
                          renderOption={(props: any, option: IAirport) => (
                            <Box
                              sx={{
                                width: "100%",
                                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                              }}
                              {...props}
                            >
                              <Typography textAlign="left">
                                {option.name} ({option.icaoCode}) -{" "}
                                {option.city}
                              </Typography>
                            </Box>
                          )}
                          // Value is determined by 'fromAirportName' which should be mapped correctly
                          value={route.fromAirportName}
                          onChange={(
                            event,
                            selectedOption: any
                          ) =>
                            handleAirportChange(
                              groupIndex,
                              aircraftIndex,
                              routeIndex,
                              "fromAirportName",
                              selectedOption
                            )
                          }
                        >
                          <img
                            src={departureIcon}
                            alt="departure-icon"
                            height="25px"
                            width="25px"
                          />
                        </AutoCompleteComponent>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <Typography>From Handler:</Typography>
                    <Grid container className={styles.quotationInput}>
                      <TextField
                        fullWidth
                        size="small"
                        type="text"
                        value={route.fromHandler}
                        onChange={(e) =>
                          handleInputChange(
                            groupIndex,
                            aircraftIndex,
                            "airportToFrom",
                            [
                              ...aircraft.airportToFrom.slice(0, routeIndex),
                              { ...route, fromHandler: e.target.value },
                              ...aircraft.airportToFrom.slice(routeIndex + 1),
                            ]
                          )
                        }
                        variant="standard"
                        placeholder="Enter from handler"
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography>
                      To Airport:<b>{route.toAirportName || airports.find((airport) => airport.airportId === route.toAirportId)?.name}</b>
                    </Typography>
                    <Grid
                      container
                      className={styles.depatureArrivalAirportBox}
                    >
                      <Grid item xs={12} className={styles.border}>
                        <AutoCompleteComponent
                          options={airports} // List of airport options
                          getOptionLabel={(option: IAirport) =>
                            `${option.name} (${option.icaoCode}) - ${option.city}`
                          } // Custom label for each option
                          renderOption={(props: any, option: IAirport) => (
                            <Box
                              sx={{
                                width: "100%",
                                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                              }}
                              {...props}
                            >
                              <Typography textAlign="left">
                                {option.name} ({option.icaoCode}) -{" "}
                                {option.city}
                              </Typography>
                            </Box>
                          )}
                          // Value is determined by 'fromAirportName' which should be mapped correctly
                          value={route.toAirportName}
                          onChange={(
                            event,
                            selectedOption: any
                          ) =>
                            handleAirportChange(
                              groupIndex,
                              aircraftIndex,
                              routeIndex,
                              "toAirportName",
                              selectedOption
                            )
                          }
                        >
                          <img
                            src={destinationIcon}
                            alt="departure-icon"
                            height="25px"
                            width="25px"
                          />
                        </AutoCompleteComponent>
                      </Grid>
                    </Grid>
                    {getErrorMessage(groupIndex, aircraftIndex, routeIndex) && (
                    <span
                      className="modalError"
                    >
                      {getErrorMessage(groupIndex, aircraftIndex, routeIndex)}
                    </span>
                  )}
                  </Grid>
                  

                  <Grid item xs={12} sm={6} md={3}>
                    <Typography>To Handler:</Typography>
                    <Grid container className={styles.quotationInput}>
                      <TextField
                        fullWidth
                        size="small"
                        type="text"
                        value={route.toHandler}
                        onChange={(e) =>
                          handleInputChange(
                            groupIndex,
                            aircraftIndex,
                            "airportToFrom",
                            [
                              ...aircraft.airportToFrom.slice(0, routeIndex),
                              { ...route, toHandler: e.target.value },
                              ...aircraft.airportToFrom.slice(routeIndex + 1),
                            ]
                          )
                        }
                        variant="standard"
                        placeholder="Enter to handler"
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={6} sm={6}>
                    <Typography>Passenger Count:</Typography>
                    <Grid container className={styles.searchInput}>
                      <TextField
                        className={styles.customInput}
                        placeholder="Number of Passengers"
                        value={route.passengerCount}
                        onChange={(e) =>
                          handleInputChange(
                            groupIndex,
                            aircraftIndex,
                            "airportToFrom",
                            [
                              ...aircraft.airportToFrom.slice(0, routeIndex),
                              { ...route, passengerCount: e.target.value },
                              ...aircraft.airportToFrom.slice(routeIndex + 1),
                            ]
                          )
                        }
                        size="small"
                        type="number"
                        fullWidth
                        variant="standard"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                src={seatIcon}
                                alt="seat icon"
                                height="25px"
                                width="25px"
                              />
                            </InputAdornment>
                          ),
                          disableUnderline: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>Start Date:</Typography>
                    <Grid
                      container
                      className={styles.depatureArrivalAirportBox}
                    >
                      <DateTimePickerComponent
                        placeholder="Start Date and Time"
                        disablePast={true}
                        value={route.startDt ? dayjs(route.startDt) : null}
                        onChange={(value) =>
                          handleInputChange(
                            groupIndex,
                            aircraftIndex,
                            "airportToFrom",
                            [
                              ...aircraft.airportToFrom.slice(0, routeIndex),
                              {
                                ...route,
                                startDt: value ? value.toISOString() : "",
                              },
                              ...aircraft.airportToFrom.slice(routeIndex + 1),
                            ]
                          )
                        }
                        //   setNewGlobalRoute({
                        //     ...newGlobalRoute,
                        //     date: value ? value.toISOString() : "", // Ensure the date is stored as a string
                        //   })
                        // }
                      />
                    </Grid>
                    {/* {routeErrors.date && <span className="modalError">{routeErrors.date}</span>} */}
                  </Grid>
                  {/* <Grid container spacing={2} mb={4}> */}
                  {/* Flight Duration Fields */}
                  <Grid item xs={12} sm={6}>
                    <Typography>Flight Duration:</Typography>
                    <Grid container spacing={2}>
                      {/* Hours Field */}
                      <Grid item xs={6}>
                        <Grid container className={styles.quotationInput}>
                          <TextField
                            label="Hours"
                            value={
                              route.flightDuration &&
                              route.flightDuration.includes("hour")
                                ? route.flightDuration.split(" ")[0] // Extract hours
                                : ""
                            }
                            onChange={(e) => {
                              const hours = e.target.value.trim(); // New hours value
                              const currentDuration =
                                route.flightDuration || ""; // Existing flightDuration

                              // Validation: If input is `0`, do nothing
                              if (hours === "0") return;

                              // Extract minutes if present
                              const minutes = currentDuration.includes("minute")
                                ? currentDuration
                                    .split(" ")
                                    .find(
                                      (part, index, array) =>
                                        array[index + 1] === "minutes"
                                    ) || ""
                                : "";

                              // Ensure at least one field is filled
                              if (!hours && !minutes) return;

                              // Update the flightDuration format
                              const updatedDuration = hours
                                ? `${hours} hours${
                                    minutes ? ` ${minutes} minutes` : ""
                                  }` // Add minutes if they exist
                                : `${minutes} minutes`; // If no hours, keep minutes only

                              // Update the state
                              handleInputChange(
                                groupIndex,
                                aircraftIndex,
                                "airportToFrom",
                                [
                                  ...aircraft.airportToFrom.slice(
                                    0,
                                    routeIndex
                                  ),
                                  {
                                    ...route,
                                    flightDuration: updatedDuration.trim(),
                                  },
                                  ...aircraft.airportToFrom.slice(
                                    routeIndex + 1
                                  ),
                                ]
                              );
                            }}
                            variant="standard"
                            size="small"
                            fullWidth
                            type="number"
                            InputProps={{
                              disableUnderline: true,
                            }}
                          />
                        </Grid>
                      </Grid>

                      {/* Minutes Field */}
                      <Grid item xs={6}>
                        <Grid container className={styles.quotationInput}>
                          <TextField
                            label="Minutes"
                            value={
                              route.flightDuration &&
                              route.flightDuration.includes("minute")
                                ? route.flightDuration
                                    .split(" ")
                                    .find(
                                      (part, index, array) =>
                                        array[index + 1] === "minutes"
                                    ) || ""
                                : ""
                            }
                            onChange={(e) => {
                              const minutes = e.target.value.trim(); // New minutes value
                              const currentDuration =
                                route.flightDuration || ""; // Existing flightDuration

                              // Validation: If input is `0` or > `59`, do nothing
                              if (minutes === "0" || parseInt(minutes) > 59)
                                return;

                              // Extract hours if present
                              const hours = currentDuration.includes("hour")
                                ? currentDuration
                                    .split(" ")
                                    .find(
                                      (part, index, array) =>
                                        array[index + 1] === "hours"
                                    ) || ""
                                : "";

                              // Ensure at least one field is filled
                              if (!minutes && !hours) return;

                              // Update the flightDuration format
                              const updatedDuration = minutes
                                ? `${
                                    hours ? `${hours} hours ` : ""
                                  }${minutes} minutes` // Add hours if they exist
                                : `${hours} hours`; // If no minutes, keep hours only

                              // Update the state
                              handleInputChange(
                                groupIndex,
                                aircraftIndex,
                                "airportToFrom",
                                [
                                  ...aircraft.airportToFrom.slice(
                                    0,
                                    routeIndex
                                  ),
                                  {
                                    ...route,
                                    flightDuration: updatedDuration.trim(),
                                  },
                                  ...aircraft.airportToFrom.slice(
                                    routeIndex + 1
                                  ),
                                ]
                              );
                            }}
                            variant="standard"
                            size="small"
                            fullWidth
                            type="number"
                            InputProps={{
                              disableUnderline: true,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* </Grid> */}
                </Grid>
                // </div>
              ))}
            </Grid>
          ))}
          <Grid item xs={12}>
              <Divider />
            </Grid>
        </Grid>
        
      ))
      
      }
      
      <Grid
        container
        spacing={2}
        justifyContent="center"
        rowGap={2}
        columnGap={2}
        mt={2}
      >
        <ButtonComponent
          fullWidth={false}
          onClick={handleSave}
          label="Update "
        />
        <ButtonComponent fullWidth={false} onClick={onClose} label="Cancel" />
      </Grid>
    </Grid>
  );
};

export default EditQuotation;
