import React, { useState, useEffect } from 'react';
import AutoCompleteComponent from '../../common/AutoCompleteComponent';
import { Autocomplete, Box, Button, Grid, InputAdornment, MenuItem, Select, TextField, Typography } from '@mui/material';
import { IAirport } from '../../../interface/IAirport';
import styles from "./GenerateQuotation.module.scss";
import seatIcon from "../../../assets/icons/seat.svg";
import departureIcon from "../../../assets/icons/depature-icon.svg";
import destinationIcon from "../../../assets/icons/destination-icon.svg";
import DateTimePickerComponent from '../../common/DateTimePickerComponent';
import dayjs from 'dayjs';
import ButtonComponent from '../../common/ButtonComponent';

interface Route {
  from: string;
  to: string;
  fromAirportName: string;
  toAirportName: string;
  fhandler: string;
  thandler: string;
  pcount: string;
  date: string;
}

interface AssignedRoute {
  route: Route;
  flightDuration: string;
  hours?: number;
  minutes?: number;
}

interface Aircraft {
  aircraftName: string;
  aircraftId: string;
  assignedRoutes: AssignedRoute[];
  currency: string;
  amount: string;
}

const EditAircraftAndAmountCurrencyWithRoute = ({
    aircrafts,
    fleetAircrafts,
    airports,
  onUpdateQuotation,
  onClose,
}: {
    aircrafts: Aircraft[];
    fleetAircrafts: any[];
    airports: any[];
  onUpdateQuotation: (updatedAircrafts: Aircraft[]) => void;
  onClose: () => void;
}) => {
  const [editableAircrafts, setEditableAircrafts] = useState<Aircraft[]>([...aircrafts]);

  const handleInputChange = (
    aircraftIndex: number,
    routeIndex: number,
    field: keyof Route | keyof AssignedRoute,
    value: string | number
  ) => {
    const updatedAircrafts = [...editableAircrafts];
    const aircraft = updatedAircrafts[aircraftIndex];
    const assignedRoute = aircraft.assignedRoutes[routeIndex];

    if (field in assignedRoute.route) {
      (assignedRoute.route as any)[field] = value;
    } else if (field in assignedRoute) {
      (assignedRoute as any)[field] = value;
    }
    setEditableAircrafts(updatedAircrafts);
  };

  const handleAircraftChange = (
    aircraftIndex: number,
    updates: { [key: string]: any }
  ) => {
    const updatedAircrafts = [...editableAircrafts];  // Shallow copy of the array
    updatedAircrafts[aircraftIndex] = {
      ...updatedAircrafts[aircraftIndex],  // Shallow copy of the aircraft object
      ...updates,  // Merge the updates object
    };
  
    // Check the state updates after changes
  
    setEditableAircrafts(updatedAircrafts);  // Update the state with the new array
  };
  
  

  const handleSave = () => {
    onUpdateQuotation(editableAircrafts);
  };

  const validateRoutes = (from: string, to: string) => {
    if (from === to) {
      return "From and To airports cannot be the same.";
    }
    return "";
  };

  return (
    <Grid container  >
        <Grid item xs={12} mb={2}>
      <Typography variant='h5'>Edit Aircraft and Route</Typography>
      </Grid>
      {editableAircrafts.map((aircraft, aircraftIndex) => (

        <Grid key={aircraft.aircraftId}>
            <Grid item xs={12} mb={1}>
                <Typography variant='h6'>Aircraft Details</Typography>
                </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} >
            <Typography>Aircraft Name:<b>{aircraft.aircraftName}</b></Typography>
            <Grid container className={styles.depatureArrivalAirportBox}>
            <Grid item xs={12} className={styles.border} >
            <AutoCompleteComponent
  options={fleetAircrafts}  // Provide your list of aircraft names here
  getOptionLabel={(option) => option.name}  // How to display the option in the dropdown
  value={editableAircrafts[aircraftIndex].aircraftName} // Set selected value here
  renderOption={(props, option) => (
    <Box component="li" {...props}>
      {option.name}
    </Box>
  )}
  onChange={(event, selectedOption) => {
    console.log("Selected Option:", selectedOption);
    if (selectedOption) {
      handleAircraftChange(aircraftIndex, {
        aircraftName: selectedOption.name,
        aircraftId: selectedOption.aircraftId,
      });
    } else {
      // Handle if no value is selected (e.g., clear the value)
      handleAircraftChange(aircraftIndex, { aircraftName: "", aircraftId: null });
    }
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      name="aircraftName"  // Corrected to a string
      value={editableAircrafts[aircraftIndex].aircraftName}  // Bind it to aircraft.aircraftName
      placeholder="Enter aircraft name"
      title="Enter the name of the aircraft"
      variant="standard"
      fullWidth
    />
  )}
/>

            </Grid>
            </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
  <Typography>Amount:</Typography>
  <Grid container className={styles.quotationInput}>
    <TextField
      fullWidth
      size="small"
      type="number"
      variant="standard"
      value={aircraft.amount}
      placeholder="Enter amount"
      onChange={(e) =>
        handleAircraftChange(aircraftIndex, {
          amount: e.target.value,
        })
      }
      InputProps={{
        disableUnderline: true,
      }}
    />
  </Grid>
</Grid>

<Grid item xs={12} sm={6} md={3}>
  <Typography>Currency:</Typography>
  <Grid container className={styles.quotationEditCurrency}>
    <Select
      label="Currency"
      value={aircraft.currency || ''}
      fullWidth
      displayEmpty
      onChange={(e) =>
        handleAircraftChange(aircraftIndex, {
          currency: e.target.value,
        })
      }
      sx={{
        border: 'none', // Removes the border
        boxShadow: 'none', // Removes any shadow
        padding: ' 0', // Adjust padding if needed
        '.MuiOutlinedInput-notchedOutline': {
          border: 'none', // Ensures no outline in certain variants
        },
      }}
    >
      <MenuItem value="INR">INR</MenuItem>
      <MenuItem value="USD">USD</MenuItem>
    </Select>
  </Grid>
</Grid>

          </Grid>

          {aircraft.assignedRoutes.map((assignedRoute, routeIndex) => (
            
            <Grid container  mb={4} className={styles.ItenaryConatiner} key={routeIndex} >
              {/* <Typography variant="subtitle1">
                Route {routeIndex + 1}: {assignedRoute.route.fromAirportName} →{" "}
                {assignedRoute.route.toAirportName}
              </Typography> */}
              <Grid container spacing={2} mt={1}>
              <Grid item xs={12} md={3}>
<Typography>From Airport:<b>{assignedRoute.route.fromAirportName}</b></Typography>
  <Grid container className={styles.depatureArrivalAirportBox}>
    <Grid item xs={12} className={styles.border}>
                  <AutoCompleteComponent
                     options={airports} // List of airport options
                     getOptionLabel={(option: IAirport) => `${option.name} (${option.icaoCode}) - ${option.city}`} // Custom label for each option
                     renderOption={(props: any, option: IAirport) => (
                       <Box
                         sx={{
                           width: "100%",
                           borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                         }}
                         {...props}
                       >
                         <Typography textAlign="left">
                           {option.name} ({option.icaoCode}) - {option.city}
                         </Typography>
                       </Box>
                     )}
                    value={assignedRoute.route.fromAirportName || ""}
                    fullWidth
                    onChange={(event, selectedOption: IAirport | null) =>{
                      handleInputChange(aircraftIndex,routeIndex,"fromAirportName",selectedOption ? selectedOption.name : '')
                      handleInputChange(aircraftIndex,routeIndex,"from",selectedOption ? selectedOption.airportId : '')
                    }}
                  >
                    <img
          src={departureIcon}
          alt="departure-icon"
          height="25px"
          width="25px"
        />
        </AutoCompleteComponent>
                </Grid>
                </Grid>
                </Grid>

               <Grid item xs={12} sm={6} md={3}>
          <Typography>From Handler:</Typography>
          <Grid container className={styles.quotationInput}>
                  <TextField
                    size="small"
                    type="text"
                    variant="standard"
                    placeholder="Enter from handler"
                    value={assignedRoute.route.fhandler}
                    fullWidth
              InputProps={{
                disableUnderline: true,
              }}
                    onChange={(e) =>
                      handleInputChange(
                        aircraftIndex,
                        routeIndex,
                        "fhandler",
                        e.target.value
                      )
                    }
                  />
                </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
          <Typography>To Airport:<b>{assignedRoute.route.toAirportName}</b></Typography>
  <Grid container className={styles.depatureArrivalAirportBox}>
    <Grid item xs={12} className={styles.border}>
                  <AutoCompleteComponent
                     options={airports}
                     getOptionLabel={(option: IAirport) =>
                       `${option.name} (${option.icaoCode}) - ${option.city}`
                     }
                     renderOption={(props: any, option: IAirport) => (
                        <Box
                          sx={{
                            width: "100%",
                            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                          }}
                          {...props}
                        >
                          <Typography textAlign="left">
                            {option.name} ({option.icaoCode}) - {option.city}
                          </Typography>
                        </Box>
                      )}
                    value={assignedRoute.route.toAirportName}
                    fullWidth
                    onChange={(event, selectedOption: IAirport | null) =>{
                        handleInputChange(aircraftIndex,routeIndex,"toAirportName",selectedOption ? selectedOption.name : '')
                        handleInputChange(aircraftIndex,routeIndex,"to",selectedOption ? selectedOption.airportId : '')
                      }}
                      >
                      <img
                         src={destinationIcon}
                         alt="departure-icon"
                         height="25px"
                         width="25px"
                       />
                       </AutoCompleteComponent>
                </Grid>
                </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
          <Typography>To Handler:</Typography>
            <Grid container className={styles.quotationInput}>
                  <TextField
                    size="small"
                    type="text"
                  variant="standard"
                    placeholder="Enter to handler"
                    value={assignedRoute.route.thandler}
                    fullWidth
                    InputProps={{
                        disableUnderline: true,
                      }}
                    onChange={(e) =>
                      handleInputChange(
                        aircraftIndex,
                        routeIndex,
                        "thandler",
                        e.target.value
                      )
                    }
                  />
                </Grid>
                </Grid>
                
                <Grid item xs={12} md={6} sm={6}>
          <Typography>Passenger Count:</Typography>
            <Grid container className={styles.searchInput}>
                  <TextField
                    className={styles.customInput}
                    placeholder="Number of Passengers"
                    value={assignedRoute.route.pcount}
                    fullWidth
                    variant="standard"
                    size="small"
                type="number"
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={seatIcon}
                              alt="seat icon"
                              height="25px"
                              width="25px"
                            />
                          </InputAdornment>
                        ),
                        disableUnderline: true,
                      }}
                    onChange={(e) =>
                      handleInputChange(
                        aircraftIndex,
                        routeIndex,
                        "pcount",
                        e.target.value
                      )
                    }
                  />
                </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
          <Typography>Start Date:</Typography>
            <Grid container className={styles.depatureArrivalAirportBox}>
          <DateTimePickerComponent
                    disablePast={true}
  placeholder="Start Date and Time"
                        value={assignedRoute.route.date ? dayjs(assignedRoute.route.date) : null}
                      
                    fullWidth
                    onChange={(value) =>
                      handleInputChange(
                        aircraftIndex,
                        routeIndex,
                        "date",
                        value ? value.toISOString() : ""
                      )
                    }
                  />
                </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
  <Typography>Flight Duration:</Typography>
  <Grid container spacing={2}>
    {/* Hours Field */}
    <Grid item xs={6}>
    <Grid container className={styles.quotationInput}>
      <TextField
        label="Hours"
        value={
          assignedRoute.flightDuration && assignedRoute.flightDuration.includes("hour")
            ? assignedRoute.flightDuration.split(" ")[0] // Extract hours
            : ""
        }
        onChange={(e) => {
          const hours = e.target.value.trim(); // New hours value
          const currentDuration = assignedRoute.flightDuration || ""; // Existing flightDuration

          // Validation: Ignore if `0` or invalid input
          if (hours === "0") return;

          // Extract minutes if present
          const minutes = currentDuration.includes("minute")
            ? currentDuration.split(" ").find((part, index, array) => array[index + 1] === "minutes") || ""
            : "";

          // Update the flightDuration format
          const updatedDuration = hours
            ? `${hours} hours${minutes ? ` ${minutes} minutes` : ""}` // Add minutes if they exist
            : `${minutes} minutes`; // If no hours, keep minutes only

          // Update the state
          handleInputChange(aircraftIndex, routeIndex, "flightDuration", updatedDuration.trim());
        }}
        variant="standard"
      size="small"
      fullWidth
      type="number"
      InputProps={{
        disableUnderline: true,
      }}
      />
    </Grid>
    </Grid>

    {/* Minutes Field */}
    <Grid item xs={6}>
    <Grid container className={styles.quotationInput}>
      <TextField
        label="Minutes"
        value={
          assignedRoute.flightDuration && assignedRoute.flightDuration.includes("minute")
            ? assignedRoute.flightDuration
                .split(" ")
                .find((part, index, array) => array[index + 1] === "minutes") || ""
            : ""
        }
        onChange={(e) => {
          const minutes = e.target.value.trim(); // New minutes value
          const currentDuration = assignedRoute.flightDuration || ""; // Existing flightDuration

          // Validation: Ignore if `0`, > `59`, or invalid input
          if (minutes === "0" || parseInt(minutes) > 59) return;

          // Extract hours if present
          const hours = currentDuration.includes("hour")
            ? currentDuration.split(" ").find((part, index, array) => array[index + 1] === "hours") || ""
            : "";

          // Update the flightDuration format
          const updatedDuration = minutes
            ? `${hours ? `${hours} hours ` : ""}${minutes} minutes` // Add hours if they exist
            : `${hours} hours`; // If no minutes, keep hours only

          // Update the state
          handleInputChange(aircraftIndex, routeIndex, "flightDuration", updatedDuration.trim());
        }}
        variant="standard"
      size="small"
      fullWidth
      type="number"
      InputProps={{
        disableUnderline: true,
      }}
      />
    </Grid>
    </Grid>
  </Grid>
</Grid>

              </Grid>
            </Grid>
          ))}
        </Grid>
      ))}

<Grid container item spacing={2} justifyContent="flex-end">
        <Grid item>
          <ButtonComponent fullWidth={false} onClick={handleSave} label='Update' />
           
        </Grid>
        <Grid item>
        <ButtonComponent fullWidth={false} onClick={onClose} label='Cancel' />

        </Grid>
      </Grid>
    </Grid>
  );
};

export default EditAircraftAndAmountCurrencyWithRoute;
